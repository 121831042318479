.hero {
  display: flex;
  justify-content: space-between;
}

/* --> left side */
.hero-left {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  /* direction */
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.the-best-add {
  margin-top: 4rem;
  background-color: var(--grey);
  border-radius: 4rem;
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  -ms-border-radius: 4rem;
  -o-border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  /* direction */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.the-best-add > span {
  z-index: 2;
}

/* Animation goes here */
.the-best-add > div {
  position: absolute;
  background-color: var(--blue);
  /* width: 5.4rem; */
  width: 25%;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  -ms-border-radius: 3rem;
  -o-border-radius: 3rem;
  z-index: 1;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  /* inherit: set it as same as the value of parent's */
  text-overflow: inherit;
}

/* nth-of-type--> selects the attribute to be changed */

.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.hero-figures {
  display: flex;
  gap: 5%;
}

.hero-figures > div {
  display: flex;
  flex-direction: column;
}

.hero-figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}

.hero-figures > div > span:nth-of-type(2) {
  color: var(--grey);
  text-transform: uppercase;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}

.hero-buttons > :nth-child(1) {
  color: white;
  background-color: var(--blue);
  width: 8rem;
}

.hero-buttons > :nth-child(2) {
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid var(--blue);
}

/* --> right side */
.hero-right {
  flex: 1;
  position: relative;
  background: var(--planCard);
}

.hero-right > .btn {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
}

.heart-rate {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: fit-content;
  padding: 1rem;
  gap: 1rem;
  background-color: var(--darkGrey);
  align-items: start;
  position: absolute;
  top: 7rem;
  right: 4rem;
}

.heart-rate > :nth-child(1) {
  width: 2rem;
}

.heart-rate > :nth-child(2) {
  color: var(--grey);
}

.heart-rate > :nth-child(3) {
  color: white;
  font-size: 1.5rem;
}

.hero-image {
  position: absolute;
  top: 3rem;
  right: 8rem;
  width: 32rem;
}

.hero-image-back {
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}

.calories {
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 33rem;
  right: 49rem;
  -webkit-border-radius: ;
  -moz-border-radius: ;
  -ms-border-radius: ;
  -o-border-radius: ;
}

.calories > img {
  width: 3rem;
}

.calories > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calories > div > :nth-child(1) {
  color: var(--grey);
  font-size: 2rem;
}
.calories > div > :nth-child(2) {
  color: white;
  text-transform: uppercase;
}

.hero-blur {
  width: 22rem;
  height: 30rem;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }

  .hero-blur {
    width: 14rem;
  }

  .the-best-add {
    margin-top: 0;
    font-size: small;
    /* align-self: center; */
    text-align: center;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }

  .hero-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }

  .hero-text > div:nth-of-type(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }

  .hero-buttons {
    justify-content: center;
  }

  .hero-figures {
    text-align: center;
  }

  .hero-figures > div > span:nth-of-type(1) {
    font-size: large;
  }
  .hero-figures > div > span:nth-of-type(2) {
    font-size: small;
  }

  .hero-right {
    position: relative;
    background: none;
  }

  .heart-rate {
    left: 1rem;
    top: 2rem;
  }

  .calories {
    position: relative;
    top: 5rem;
    left: 2rem;
  }

  .calories > img {
    width: 2rem;
  }
  .calories > div > :nth-child(1) {
    font-size: 1rem;
  }
  
  .hero-image {
    position: relative;
    align-self: center;
    width: 20rem;
    left: 1rem;
    top: 3rem;
  }

  .hero-image-back {
    width: rem;
    left: 2rem;
    top: 0rem;
  }
}
